import empty from "images/empty.svg";
import { memo } from "react";

interface EmptyTableProps {
  title: string;
  subtitle: React.ReactNode;
}

function EmptyTable(props: EmptyTableProps) {
  const { title, subtitle } = props;

  return (
    <section className="h-[20rem] flex items-center justify-center flex-col bg-white h-inherit">
      <img loading="lazy" src={empty} alt="Empty" className="h-32 w-32" />

      <h3 className="font-semibold text-2xl mb-2 mt-7">{title}</h3>
      <p className="text-sm">{subtitle}</p>
    </section>
  );
}

export default memo(EmptyTable);
