import useInvites from "hooks/useInvites";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import useInviteService from "services/invite.service";
import { PendingUserType } from "utils";

type InviteViewType = "invite" | "revoke";

interface InviteContextInterface {
  revokeUser: PendingUserType | null;
  setRevokeUser: Dispatch<SetStateAction<PendingUserType | null>>;
  inviteView: InviteViewType;
  setInviteView: Dispatch<SetStateAction<InviteViewType>>;
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;

  setClickedFrom: Dispatch<SetStateAction<"nav" | "settings" | null>>;

  handleCustomCancelModal: () => void;
  toggleInviteView: () => void;

  toggleModal: () => void;
  handleCustomCloseModal: () => void;
  deleteIsLoading: boolean;
  handleDeleteInvite: (id: string) => Promise<void>;
}

const InviteContext = createContext<InviteContextInterface>(
  {} as InviteContextInterface
);

/**
 * The invite provider file handles the team invite functionality
 * to access the screen
 * check settings > team
 */

function InviteProvider({ children }: { children: ReactNode }) {
  const [clickedFrom, setClickedFrom] = useState<"nav" | "settings" | null>(
    null
  );

  const [revokeUser, setRevokeUser] = useState<PendingUserType | null>(null);
  const [inviteView, setInviteView] = useState<InviteViewType>("invite");
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { deleteInvite, deleteIsLoading } = useInviteService();
  const { getInvites } = useInvites();

  const toggleModal = () => setModalVisible((x) => !x);

  const toggleInviteView = () =>
    setInviteView((v) => (v === "invite" ? "revoke" : "invite"));

  const handleDeleteInvite = async (id: string) => {
    await deleteInvite(id);
    await getInvites();
    toggleInviteView();

    if (clickedFrom === "settings") {
      setModalVisible(false);
    }
  };

  const handleCustomCloseModal = () => {
    resetInviteData();
    setModalVisible(false);
  };

  const resetInviteData = () => {
    setInviteView("invite");
    setRevokeUser(null);
  };

  const handleCustomCancelModal = () =>
    clickedFrom === "settings" ? handleCustomCloseModal() : resetInviteData();

  return (
    <InviteContext.Provider
      value={{
        revokeUser,
        setRevokeUser,
        inviteView,
        setInviteView,
        modalVisible,
        setModalVisible,

        setClickedFrom,

        handleCustomCloseModal,
        handleCustomCancelModal,

        toggleModal,
        toggleInviteView,
        deleteIsLoading,

        handleDeleteInvite,
      }}
    >
      {children}
    </InviteContext.Provider>
  );
}

function useInvite() {
  const context = useContext(InviteContext);

  if (!context) {
    throw new Error("useInvite must be used within an InviteProvider");
  }

  return context;
}

export { useInvite, InviteProvider };
