import { memo } from "react";

interface ChipProps {
  title: string;
  onDelete?: () => void;
}

function Chip(props: ChipProps) {
  const { title, onDelete } = props;

  return (
    <span
      data-testid="test-Chip"
      className="px-2 py-1 rounded-full text-gray-500 bg-gray-200 font-normal text-xs flex items-center gap-2 align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease"
    >
      {title}
      <button
        className="bg-transparent hover focus:outline-none"
        onClick={onDelete}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </span>
  );
}

export default memo(Chip);
