import { AxiosError } from 'axios';
import useQueryData from "hooks/useQueryData";
import { useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { api, API_ROUTES, CatchErrorType, displayErrors, isArray, toast } from "utils";

const useInviteService = () => {
    const { getAccountData } = useQueryData();

    const { mutateAsync: postMutateAsync, isLoading: postIsLoading } = useMutation(api.post);
    const { mutateAsync, isLoading: deleteIsLoading } = useMutation(api.delete);
    const [isLoading, setIsLoading] = useState(false);

    const getInvitedEmailsApi = useQuery({
        queryKey: 'get-invited-emails-api',
        queryFn: () => {
            const accountId = getAccountData()?.id;
            const url = API_ROUTES.INVITE_EMAIL.replace(':accountId', accountId);

            return api.get(url);
        },
        enabled: false,
        retry: 0
    });

    const getInvitedEmails = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await getInvitedEmailsApi.refetch();

            if (status !== "error" && data) {
              return data;
            }
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const inviteEmail = async (values: Record<string, string | string[]>) => {
        try {
            const accountId = getAccountData()?.id;

            if (accountId) {
                const url = API_ROUTES.INVITE_EMAIL.replace(':accountId', accountId);

                const { data, message, status } = await postMutateAsync([url, values]);

                if (status !== "error" && data) {
                    if ("success" in data) {
                        const successLen = data.success.length;
                        if (successLen > 0) {
                            toast(message, "success");
                        }
                    }

                    return data;
                }

                toast(message, "error");
            }

            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        }
    };

    const resendInviteEmail = async (values: Record<string, string[]>, inviteId: string) => {
        try {
            const accountId = getAccountData()?.id;

            if (accountId) {
                const url = API_ROUTES.RESEND_INVITE_EMAIL.replace(':accountId', accountId).replace(':inviteId', inviteId);

                const { message, status } = await postMutateAsync([url, values]);

                toast(message, status);
            }

            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        }
    };

    const deleteInvite = async (invite_id: string) => {
        try {
            const accountId = getAccountData()?.id;
            const url = `${API_ROUTES.INVITE_EMAIL.replace(':accountId', accountId)}/${invite_id}`;

            const { data, message, status } = await mutateAsync(url);

            if (status !== "error" && data) {
                toast(message, "success");
                return data;
            }

            toast(message, "error");
            return false;
        } catch (error: unknown) {
            const err = error as AxiosError;
            const message = err?.message;
            if (!isArray(message)) {
                toast(message, "error");
            } else {
                displayErrors(error as CatchErrorType);
            }

            return false;
        }
    };

    return {
        isLoading,
        postIsLoading,
        deleteIsLoading,

        getInvitedEmails,
        deleteInvite,
        inviteEmail,
        resendInviteEmail,
    };
};

export default useInviteService;
