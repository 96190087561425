import * as Sentry from "@sentry/react";
import { LoadingLogo } from "components";
import { MobileError } from "partials";
import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useUserService from "services/user.service";
import { clearStorage } from "utils";

interface AuthContextInterface {}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

/**
 * This provider act as a wrapper to check if the user is authenticated or not
 * moves to the dashboard screen if authenticated
 * moves to the login screen is unauthenticated.
 * default - login screen
 */

function AuthProvider() {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const { hostname } = window.location;
  const { getUser } = useUserService();

  const redirectToDashboard = () => {
    setIsLoggedIn(true);
    return navigate(`/dashboard`);
  };

  const handleNoLoginSuccess = async () => {
    setIsLoggedIn(false);
    clearStorage();

    return true;
  };

  const checkIfLogin = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("access_token");
      const inDemoMode = hostname.includes("demo.getre.io");

      if (inDemoMode) {
        return redirectToDashboard();
      }

      if (!token) {
        return handleNoLoginSuccess();
      }

      const result = await getUser();
      if (result && result?.data?.email) {
        Sentry.setUser({ email: result?.data?.email });
        return redirectToDashboard();
      } else {
        return handleNoLoginSuccess();
      }
    } catch (error) {
      return handleNoLoginSuccess();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsLoading(false);
      setShowMobileWarning(true);
    } else {
      checkIfLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{}}>
      {isLoading && <LoadingLogo />}
      {showMobileWarning ? <MobileError /> : <>{!isLoggedIn && <Outlet />}</>}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { useAuth, AuthProvider };
