import { createContext, ReactNode, useContext, useState } from "react";
import { CurrentUploadType, UploadType } from "utils";

interface UploadContextInterface {
  page: number;
  uploadType: UploadType;
  updatePage: (id: number) => Promise<void>;
  updateUploadType: (type: UploadType) => {};
  currentUploadInfo: null | CurrentUploadType;
  updateCurrentUploadInfo: (props: CurrentUploadType | null) => Promise<void>;
}

const UploadContext = createContext<UploadContextInterface>(
  {} as UploadContextInterface
);

function UploadProvider({ children }: { children: ReactNode }) {
  const [page, setProviderPage] = useState<number>(1);
  const [uploadType, setProviderUploadType] = useState<UploadType>("");

  const [currentUploadInfo, setProviderCurrentUploadInfo] =
    useState<null | CurrentUploadType>(null);

  const updateCurrentUploadInfo = async (props: CurrentUploadType | null) => {
    if (props === null) {
      setProviderCurrentUploadInfo(null);
      return;
    }

    const { id, url } = props as CurrentUploadType;
    await setProviderCurrentUploadInfo({ id, url });
  };

  const updatePage = async (id: number) => await setProviderPage(id);

  const updateUploadType = async (type: UploadType) => {
    await setProviderUploadType(type);
    await setProviderPage(1);
  };

  return (
    <UploadContext.Provider
      value={{
        page,
        uploadType,
        updatePage,
        updateUploadType,
        currentUploadInfo,
        updateCurrentUploadInfo,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
}

function useUpload() {
  const context = useContext(UploadContext);

  if (!context) {
    throw new Error("useUpload must be used within an UploadProvider");
  }

  return context;
}

export { useUpload, UploadProvider };
