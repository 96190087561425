import { memo } from "react";
import "./info-card.css";

interface InfoCardProps {
  title: string;
  icon?: any;
  buttonText: string;
  buttonAction: () => void;
}

function InfoCard(props: InfoCardProps) {
  const { title, icon, buttonText, buttonAction } = props;

  return (
    <div
      className="info-card-container flex items-center justify-between text-blue-10 rounded-[7px] bg-[rgba(57, 113, 203, 0.1)] border border-blue-10 p-4"
      data-testid="test-InfoCard"
    >
      <div className="flex items-center gap-2">
        {icon}

        <p className="text-black text-xs">{title}</p>
      </div>

      <button onClick={buttonAction} className="text-blue-10 text-xs">
        {buttonText}
      </button>
    </div>
  );
}

export default memo(InfoCard);
