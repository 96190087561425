import { Button } from "components";
import { memo } from "react";
import "./alert-info-card.css";

interface AlertInfoCardProps {
  title: string;
  subtitle?: string;
  icon?: any;
  buttonText?: string;
  buttonAction?: () => void;
}

function AlertInfoCard(props: AlertInfoCardProps) {
  const { title, subtitle, icon, buttonText, buttonAction } = props;

  return (
    <div className="alert-info-card-container" data-testid="test-AlertInfoCard">
      <div className="flex items-center gap-4">
        {icon}
        <div>
          <h6 className="font-bold text-black-60 mb-1">{title}</h6>
          <p className="text-black-60">{subtitle}</p>
        </div>
      </div>

      {buttonText ? (
        <div className="min-w-[11.25rem]">
          <Button type="button" text={buttonText} onClick={buttonAction} />
        </div>
      ) : null}
    </div>
  );
}

export default memo(AlertInfoCard);
