import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse
} from "axios";
import { clearStorage, getBaseUrl, getCookie, toast } from "utils";

function ApiResource() {
  let controller = new AbortController()

  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL || '*',
      credentials: 'same-origin',
    },
  }) as AxiosInstance;

  api.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config?.headers) {
        if (config?.url?.includes('login')) {
          controller = new AbortController();
        }
        config.signal = controller.signal

        const token = localStorage.getItem('access_token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (config.method !== 'get') {
          config.headers['X-CSRF-TOKEN'] = getCookie('csrf_access_token');
        }

        return config;
      }
    },

    (error: AxiosError) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response: AxiosResponse) => response,

    (error: AxiosError) => {
      const errorData = error?.response?.data as Record<string, string | object>;
      const statusCode = Number(error?.response?.status);
      const errorCode = ['ERR_CANCELED', 'ERR_NETWORK'];

      if (error.response === undefined || (error.code && errorCode.includes(error.code))) {
        if (error.code !== 'ERR_CANCELED') {
          toast(error.message || "No internet connection!", "error");
        }
      } else if (Number(errorData?.code) === 401 || (statusCode > 400 && statusCode < 452)) {
        if (statusCode === 403) {
          return;
        }
        toast('Token Expired, Please Login!', "error");
        clearStorage();

        controller.abort();

        window.location.href = `${getBaseUrl()}#/login`;
        return window.location.reload();
      } else if (Number(errorData?.code) === 302 || statusCode === 302) {
        clearStorage();
        return window.location.href = error?.response?.headers?.location;
      }

      return Promise.reject(errorData);
    },
  );

  return {
    get: (url: string) => api.get(url).then(({ data }) => data),

    post: (args: [string, object?]) => api.post(...args).then(({ data }) => data),

    patch: (args: [string, object]) => api.patch(...args).then(({ data }) => data),

    put: (args: [string, object?]) => api.put(...args).then(({ data }) => data),

    delete: (url: string) => api.delete(url).then(({ data }) => data),
  };
}

export const api = ApiResource();
