import { Button } from "components";
import useBilling from "hooks/useBilling";
import useQueryData from "hooks/useQueryData";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import useAccountService from "services/account.service";
import { currencyToSymbol } from "utils";

function TrialEnds(): JSX.Element {
  const navigate = useNavigate();
  const { getUserData } = useQueryData();

  const { subscribe, postIsLoading } = useAccountService();
  const user = getUserData();

  const {
    hasEndedTrial,
    incompleteSubscription,
    handleManageSubscription,
    currentSubscriptionPrice,
    accountSubscriptionStatus,
    currentSubscriptionCurrency,
  } = useBilling();

  const handleClick = async () => {
    if (incompleteSubscription) {
      return handleManageSubscription();
    }

    if (hasEndedTrial && !user?.email_verified) {
      return navigate("/settings/billing");
    }

    const res = await subscribe();
    if (res) {
      window.location.href = res.url;
      return;
    }
  };

  return (
    <div className="flex items-center justify-center flex-col text-center text-black-10">
      <div className="flex flex-col items-center justify-center relative">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641.513 626.392">
          <path
            d="M198.101 459.506h-80.357c-2.026 0-3.674-1.649-3.674-3.674s1.649-3.674 3.674-3.674h80.357c2.026 0 3.674 1.648 3.674 3.673s-1.648 3.675-3.674 3.675Z"
            fill="#6c63ff"
          />
          <path
            d="M54.587 479.173c0 .66.53 1.19 1.19 1.19h204.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H55.778c-.66 0-1.19.53-1.19 1.19Z"
            fill="#3f3d56"
          />
          <circle cx={485.607} cy={174.403} r={65} fill="#ff6884" />
          <path
            d="M0 561.308c0 .66.53 1.19 1.19 1.19h522.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H1.19c-.66 0-1.19.53-1.19 1.19Z"
            fill="#3f3d56"
          />
          <g fill="#3f3d56">
            <path d="m7.555 480.947-1.67-1.83L168.947 330.32l58.749 26.336 63.44-108.794 52.122-25.124 48.293 80.488c26.072-67.499 91.46-138.118 169.81-209.766l1.927 1.56c-73.485 70.355-136.358 141.028-172.04 212.52l-44.669-74.666a8.025 8.025 0 0 0-10.271-3.156 154.31 154.31 0 0 0-68.115 62.023l-39.74 67.97-9.07-4.066c-30.339-13.6-65.855-7.9-90.414 14.51L7.555 480.948Z" />
            <path d="m565.038 107.006-2.476.172-3.994-8.658-8.944-1.816-.348-2.457 14.744-2.096 1.018 14.855z" />
          </g>
          <path
            fill="#ffb6b6"
            d="m310.684 159.98 8.766-33.697 34.147-1.643 10.216 46.45-53.129-11.11z"
          />
          <path
            opacity={0.1}
            d="m310.684 159.98 8.766-33.697 34.147-1.643 10.216 46.45-53.129-11.11z"
          />
          <circle cx={333.154} cy={103.924} r={41.216} fill="#ffb6b6" />
          <path
            d="M355.894 112.649s-15.657-31.023-34.002-29.232c-18.346 1.791-28.01 3.97-28.01 3.97s-5.446-24.162 20.192-33.456c0 0-32.598-32.134 6.213-47.897 38.811-15.763 60.529 4.34 60.529 4.34s28.728 28.68-7.454 50.115c0 0 26.866 36.14-5.662 65.926 0 0 7.275-24.104-.316-19.66-7.592 4.446-11.49 5.894-11.49 5.894ZM287.679 330.412l-5.483 5.254-39.607 84.925-4.336 140.472H304.8l31.216-69.563 21.032 68.892h59.835l13.87-125.06-37.286-88.445-105.787-16.475z"
            fill="#2f2e41"
          />
          <path
            d="M356.614 156.556h-44.441l-48.34 26.013 11.272 50.293s-2.6 30.348 3.469 52.893c6.07 22.545 3.468 52.027 3.468 52.027l111.424 9.105 3.469-102.32-1.735-68.501-38.586-19.51Z"
            fill="#e6e6e6"
          />
          <path
            d="M271.203 183.003s-26.88.867-31.216 18.21-7.804 106.654-7.804 106.654 2.602 52.996 31.65 27.798c29.048-25.197 26.447-101.503 26.447-101.503l-19.077-51.16Z"
            fill="#e6e6e6"
          />
          <path
            d="M531.927 625.392H373.518c-5.364 0-9.73-4.365-9.73-9.73V451.904c0-5.365 4.366-9.73 9.73-9.73h158.41c5.364 0 9.729 4.365 9.729 9.73v163.758c0 5.365-4.365 9.73-9.73 9.73Z"
            fill="#fff"
          />
          <path
            d="M531.927 626.392h-158.41c-5.915 0-10.729-4.813-10.729-10.73V451.904c0-5.916 4.814-10.73 10.73-10.73h158.41c5.915 0 10.729 4.814 10.729 10.73v163.758c0 5.917-4.814 10.73-10.73 10.73Zm-158.41-183.218c-4.813 0-8.729 3.916-8.729 8.73v163.758c0 4.814 3.916 8.73 8.73 8.73h158.41c4.813 0 8.729-3.916 8.729-8.73V451.904c0-4.814-3.916-8.73-8.73-8.73h-158.41Z"
            fill="#3f3d56"
          />
          <g fill="#6c63ff">
            <path d="M458.974 582.37h-13.056c-1.59 0-2.883-.998-2.883-2.225v-56.211c0-1.227 1.293-2.225 2.883-2.225h13.056c1.59 0 2.883.998 2.883 2.225v56.211c0 1.227-1.293 2.226-2.883 2.226ZM415.81 549.245c-1.285 0-2.33.806-2.33 1.798v29.04c0 .991 1.045 1.798 2.33 1.798h13.056c1.284 0 2.329-.807 2.329-1.798v-29.04c0-.992-1.045-1.798-2.33-1.798H415.81ZM489.082 582.37h-13.056c-1.59 0-2.882-.998-2.882-2.225v-92.12c0-1.227 1.293-2.225 2.882-2.225h13.056c1.59 0 2.883.998 2.883 2.225v92.12c0 1.227-1.293 2.226-2.883 2.226Z" />
          </g>
          <g fill="#6c63ff">
            <path d="M208.125 178.572H193.21c-1.816 0-3.293-1.14-3.293-2.542v-64.21c0-1.401 1.477-2.541 3.293-2.541h14.914c1.816 0 3.293 1.14 3.293 2.542v64.21c0 1.4-1.477 2.541-3.293 2.541ZM158.819 140.733c-1.467 0-2.661.921-2.661 2.053v33.172c0 1.133 1.194 2.054 2.66 2.054h14.914c1.467 0 2.661-.921 2.661-2.054v-33.172c0-1.132-1.194-2.053-2.66-2.053h-14.914ZM242.517 178.572h-14.914c-1.815 0-3.292-1.14-3.292-2.542V70.802c0-1.401 1.477-2.542 3.292-2.542h14.914c1.816 0 3.293 1.14 3.293 2.542V176.03c0 1.402-1.477 2.542-3.293 2.542Z" />
          </g>
          <path
            d="M428.438 466c7.724-.739 13.043-11.177 11.881-23.32a30.515 30.515 0 0 0-4.535-13.68l-1.489-13.741.228-.069 3.743-65.158c.87-15.15-5.127-30.037-16.682-39.874-6.873-5.853-14.205-8.816-18.92-1.421-7.824 12.27 2.31 62.846 8.965 91.601l2.58 30.728a30.517 30.517 0 0 0-1.86 14.296c1.16 12.138 8.364 21.378 16.088 20.639Z"
            fill="#ffb6b6"
          />
          <path
            d="M385.662 177.8s22.545-8.671 32.95 17.342 21.678 154.346 21.678 154.346l-52.894-9.538-1.734-162.15ZM76.092 165.076H45.735c-2.026 0-3.675-1.648-3.675-3.674s1.649-3.673 3.675-3.673h30.357c2.026 0 3.673 1.647 3.673 3.673s-1.647 3.674-3.673 3.674ZM76.092 112.997H45.735c-2.026 0-3.675-1.648-3.675-3.674s1.649-3.674 3.675-3.674h30.357c2.026 0 3.673 1.648 3.673 3.674s-1.647 3.674-3.673 3.674ZM76.092 75.44H45.735c-2.026 0-3.675-1.648-3.675-3.674s1.649-3.674 3.675-3.674h30.357c2.026 0 3.673 1.648 3.673 3.674s-1.647 3.674-3.673 3.674Z"
            fill="#e6e6e6"
          />
          <path
            d="M40.843 178.754c0 .66.53 1.19 1.19 1.19h204.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H42.033c-.66 0-1.19.53-1.19 1.19ZM434.843 486.754c0 .66.53 1.19 1.19 1.19h204.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19h-204.29c-.66 0-1.19.53-1.19 1.19ZM374.843 583.754c0 .66.53 1.19 1.19 1.19h204.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19h-204.29c-.66 0-1.19.53-1.19 1.19Z"
            fill="#3f3d56"
          />
          <path
            d="M237.467 172.738c-7.724.74-13.044 11.178-11.882 23.32a30.515 30.515 0 0 0 4.535 13.68l1.49 13.742-.229.068-3.742 65.158c-.87 15.15 5.127 30.037 16.681 39.875 6.874 5.852 14.206 8.815 18.921 1.42 7.824-12.269-2.31-62.846-8.965-91.6l-2.58-30.729a30.517 30.517 0 0 0 1.86-14.296c-1.161-12.138-8.364-21.378-16.089-20.638Z"
            fill="#ffb6b6"
          />
        </svg>
      </div>

      <p className="mt-6 tracking-[0.06rem]">
        {accountSubscriptionStatus === null
          ? "Your 30-day free trial expired. "
          : accountSubscriptionStatus === "canceled"
          ? "Your subscription expired. "
          : incompleteSubscription
          ? "Your payment failed. "
          : ""}
        You can still have access to full functionality of the platform if you
        {incompleteSubscription ? " manage subscription." : " subscribe."}
      </p>

      <h2 className="mt-5 text-black font-semibold">
        {currentSubscriptionPrice ? (
          <Fragment>
            <>
              {`${currencyToSymbol(
                currentSubscriptionCurrency
              )}${currentSubscriptionPrice}`}
            </>

            <span className="ml-1 text-[#aaa9a9] text-sm">
              flat monthly fee <br /> (unlimited number of users)
            </span>
          </Fragment>
        ) : (
          "Unavailable"
        )}
      </h2>

      <ul className="flex flex-col gap-3 text-black-10 text-sm my-8 w-3/5 justify-start">
        <li className="flex gap-3 items-center">
          <div className="w-5 h-5 rounded-full bg-green-10 grid place-items-center">
            <img
              src="https://uploads-ssl.webflow.com/631a015cf67570422bb8e32d/631a015cf6757000f9b8e38e_check-small.svg"
              alt="check"
              className="w-[10px] h-[10px] "
            />
          </div>
          Unlimited team members
        </li>
        <li className="flex gap-3 items-center">
          <div className="w-5 h-5 rounded-full bg-green-10 grid place-items-center">
            <img
              src="https://uploads-ssl.webflow.com/631a015cf67570422bb8e32d/631a015cf6757000f9b8e38e_check-small.svg"
              alt="check"
              className="w-[10px] h-[10px] "
            />
          </div>
          Your metadata reports in one shared space
        </li>
        <li className="flex gap-3 items-center">
          <div className="w-5 h-5 rounded-full bg-green-10 grid place-items-center">
            <img
              src="https://uploads-ssl.webflow.com/631a015cf67570422bb8e32d/631a015cf6757000f9b8e38e_check-small.svg"
              alt="check"
              className="w-[10px] h-[10px] "
            />
          </div>
          Easily access the version history of your data
        </li>
        <li className="flex gap-3 items-center">
          <div className="w-5 h-5 rounded-full bg-green-10 grid place-items-center">
            <img
              src="https://uploads-ssl.webflow.com/631a015cf67570422bb8e32d/631a015cf6757000f9b8e38e_check-small.svg"
              alt="check"
              className="w-[10px] h-[10px] "
            />
          </div>
          Get notified on Slack
        </li>
        <li className="flex gap-3 items-center">
          <div className="w-5 h-5 rounded-full bg-green-10 grid place-items-center">
            <img
              src="https://uploads-ssl.webflow.com/631a015cf67570422bb8e32d/631a015cf6757000f9b8e38e_check-small.svg"
              alt="check"
              className="w-[10px] h-[10px] "
            />
          </div>
          and much more...
        </li>
      </ul>

      <div className="flex items-center justify-center min-w-[12rem] mb-6">
        <Button
          text={incompleteSubscription ? "Manage Subscription" : "Subscribe"}
          onClick={handleClick}
          isLoading={postIsLoading}
        />
      </div>
    </div>
  );
}

export { TrialEnds };
