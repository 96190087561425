import useInvites from "hooks/useInvites";
import { FormEventHandler, useState } from "react";
import useInviteService from "services/invite.service";
import { emailRegEx } from "utils";

function useListInput() {
    const [items, setItems] = useState<string[]>([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState<null | string>(null);

    const { getInfo } = useInvites();
    const { inviteEmail, postIsLoading } = useInviteService();

    const handleKeyDown = (evt: any) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            const val = value.trim();

            if (val && isValid(val)) {
                setItems((i: any) => [...i, val]);
                setValue("");
            }
        }
    };

    const handleChange = (evt: any) => {
        setValue(evt.target.value);
        setError(null);
    };

    const handleDelete = (item: any) => {
        setItems((i: any) => i.filter((i: any) => i !== item));
    };

    const handlePaste = (evt: any) => {
        evt.preventDefault();

        const paste = evt.clipboardData.getData("text");
        // eslint-disable-next-line no-useless-escape
        const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            const toBeAdded = emails.filter((e: string) => !isInList(e));

            setItems((i: any) => [...i, ...toBeAdded]);
        }
    };

    const isValid = (email: string) => {
        let err = null;

        if (isInList(email)) {
            err = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            err = `${email} is not a valid email address.`;
        }

        if (err) {
            setError(err);
            return false;
        }

        return true;
    };

    const isInList = (email: string) => items.includes(email);

    const isEmail = (email: string) => emailRegEx.test(email);

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        try {
            e.preventDefault();

            let err = null;
            setError(err);

            if (items.length < 1) {
                setError("Add at least one email to continue.");
                return;
            }

            let values: Record<string, string | string[]> = {
                emails: items
            };

            const result = await inviteEmail(values);

            if ("errors" in result && result.errors.length > 0) {
                const errorEmails = result.errors.join(", ");
                err = `${errorEmails} has already been added.`;

                setError(err);
            } else {
                setItems([]);
            }
        } catch (error) { }
        finally {
            await getInfo();
        }
    };

    return {
        value,
        items,
        error,
        onSubmit,
        setError,
        handlePaste,
        handleChange,
        handleDelete,
        handleKeyDown,
        postIsLoading,
    }

}

export default useListInput;
