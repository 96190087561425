import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useQueryData from "hooks/useQueryData";
import { useState } from "react";
import useAccountService from "services/account.service";
import useInviteService from "services/invite.service";
import { dateTimeFormat } from "utils";

dayjs.extend(utc);

type UploadsProps = {
    name?: string | null,
    id?: string,
}

function useInvites() {
    const { getUserData } = useQueryData();
    const user = getUserData();
    const [teams, setTeams] = useState<Record<string, string>[]>([]);
    const [invites, setInvites] = useState<Record<string, string>[]>([]);

    const { getTeamByAccount, isLoading } = useAccountService();
    const { getInvitedEmails, isLoading: isLoadingInvite, deleteIsLoading } = useInviteService();

    const getTeam = async () => {
        const res = await getTeamByAccount();

        const result: Record<string, string>[] = [];
        const data = res?.data;

        for (let index = 0; index < data?.length; index++) {
            const element = data[index];

            const tag = !!element?.is_admin ? "(owner)" : user?.email === element?.email ? "(you)" : ""

            result.push({
                ...element,
                name: `${element.first_name} ${element.last_name} ${tag}`,
                date: `${dayjs.utc(element.time_created).format(dateTimeFormat)}`,
            });
        }

        setTeams(result);
    };

    const getInvites = async () => {
        const res = await getInvitedEmails();

        const result: Record<string, string>[] = [];
        const data = res?.data;

        for (let index = 0; index < data?.length; index++) {
            const element = data[index];
            result.push({
                ...element,
                company_name: element?.inviter?.company_name,
                date: element.joined ? `${dayjs.utc(element.time_created).format(dateTimeFormat)}` : 'invitation pending',
            });
        }

        setInvites(result);
    };

    const getInfo = async () =>
        await Promise.all([await getTeam(), await getInvites()]);

    const generateUploadUrl = ({ name, id }: UploadsProps): string => {
        return name && id ? `/uploads?id=${id}&name=${name}` : `/uploads?name=${name}`;
    };

    return {
        teams,
        invites,
        getInfo,
        isLoading: isLoading || isLoadingInvite,
        getInvites,
        deleteIsLoading,
        generateUploadUrl,
    };
}

export default useInvites;
