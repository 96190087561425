import search from "images/search.svg";
import { forwardRef, LegacyRef, memo } from "react";
import { FieldErrors } from "react-hook-form";
import { generateInputId } from "utils";

interface InputProps {
  placeholder?: string;
  type?: string;
  isSearch?: boolean;
  label?: string;
  value?: string;
  error?: FieldErrors;
  variant?: "labelled" | "unlabelled";
}

function Input(
  props: InputProps,
  ref: LegacyRef<HTMLInputElement> | undefined
) {
  const {
    label,
    error,
    value,
    isSearch,
    placeholder,
    type = "text",
    variant = "labelled",
    ...rest
  } = props;

  const id = generateInputId(label || placeholder || `${type}Input`);

  return (
    <div
      className={`input-container ${
        isSearch ? "mb-0" : "mb-1"
      } w-full border-0`}
      data-testid="test-Input"
    >
      {variant === "labelled" && (
        <div className="form-floating mb-1 w-full">
          <input
            id={id}
            name={label}
            aria-invalid={error?.message ? "true" : "false"}
            type={type}
            value={value}
            className="form-control block w-full px-3 py-1.5 text-sm font-normal text-black-10 bg-white bg-clip-padding border border-solid border-gray-20 rounded transition ease-in-out m-0 focus:text-black-10 focus:bg-white focus:border-primary2 focus:outline-none"
            placeholder={placeholder || label}
            ref={ref}
            {...rest}
          />
          {label && (
            <label htmlFor={id} className="text-black-10">
              {label}
            </label>
          )}
        </div>
      )}
      {variant === "unlabelled" && (
        <div className="relative flex overflow-hidden gap-x-2 items-center w-full h-[34px] rounded-md bg-white">
          {isSearch && (
            <img
              src={search}
              alt="search"
              className="absolute ml-[9px] w-4 h-4"
            />
          )}

          <input
            id={id}
            name={label}
            aria-invalid={error?.message ? "true" : "false"}
            type={type}
            value={value}
            className={
              isSearch
                ? "w-full h-full pl-[33px] pr-2 py-1 text-sm text-[#10101090] opacity-[59] placeholder-current focus:border-none focus:ring-none focus-visible:outline-none"
                : "form-control block w-full pl-3 pr-3 py-1.5 text-sm font-normal text-black-10 bg-white bg-clip-padding border-b border-solid border-b-gray-300 transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-b-primary2 focus:outline-none focus:shadow-none focus-visible:outline-none"
            }
            placeholder={placeholder || label}
            ref={ref}
            {...rest}
          />
        </div>
      )}
      {error && (
        <div className="text-sm text-red-500" role="alert">
          {error.message}
        </div>
      )}
    </div>
  );
}

export default memo(forwardRef(Input));
