import { memo } from "react";
import { Button } from "../Button";

interface ModalProps {
  modalVisible: boolean;
  title: string;
  showCloseButton?: boolean;
  closeModal: () => void;
  modalContent: JSX.Element;
}

function Modal(props: ModalProps) {
  const {
    modalVisible,
    title,
    closeModal,
    modalContent,
    showCloseButton = true,
  } = props;

  return (
    <div
      data-testid="test-Modal"
      className={`modal-container fixed ${
        modalVisible ? "flex" : "hidden"
      } z-50 inset-0 bg-[#E8EAF0CC] overflow-y-auto h-full w-full items-center justify-center `}
    >
      <div className="my-modal-shadow relative w-[600px] bg-white rounded-[8px] border-2 border-[#F8F8F8]  ">
        <div className="flex items-center justify-between py-[25px] px-[35px] border-b border-[#DEE0EA]">
          <h1 className="w-full text-center font-semibold text-[25px] leading-[32px] text-black-10">
            {title}
          </h1>
          {showCloseButton && (
            <button onClick={closeModal}>
              <svg
                width={11}
                height={16}
                className="w-[11px] h-[16px]"
                viewBox="0 0 11 16"
                fill="fillCurrent"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.585 8L10.7122 4.87281C11.0959 4.48906 11.0959 3.86687 10.7122 3.48281L10.0172 2.78781C9.63344 2.40406 9.01125 2.40406 8.62719 2.78781L5.5 5.915L2.37281 2.78781C1.98906 2.40406 1.36688 2.40406 0.982813 2.78781L0.287813 3.48281C-0.0959375 3.86656 -0.0959375 4.48875 0.287813 4.87281L3.415 8L0.287813 11.1272C-0.0959375 11.5109 -0.0959375 12.1331 0.287813 12.5172L0.982813 13.2122C1.36656 13.5959 1.98906 13.5959 2.37281 13.2122L5.5 10.085L8.62719 13.2122C9.01094 13.5959 9.63344 13.5959 10.0172 13.2122L10.7122 12.5172C11.0959 12.1334 11.0959 11.5113 10.7122 11.1272L7.585 8Z"
                  fill="#101010"
                  fillOpacity="0.59"
                />
              </svg>
            </button>
          )}
        </div>
        <div className="p-[20px] w-full">{modalContent}</div>
      </div>
    </div>
  );
}

interface ModalFooterProps {
  btnCancelText: string;
  btnOkText: string;
  btnCancelAction: () => void;
  btnOkAction: () => void;
  btnOkIsLoading?: boolean;
}

function ModalFooter(props: ModalFooterProps) {
  const {
    btnCancelText,
    btnCancelAction,
    btnOkText,
    btnOkAction,
    btnOkIsLoading,
  } = props;

  return (
    <div className="w-full flex items-center justify-end gap-[25px]">
      <button
        className="px-[24px] py-[10.25px] border border-[#DEE0EA] rounded-[6px] text-black-10 text-[16px] font-medium leading-[20px] tracking-wider"
        onClick={btnCancelAction}
      >
        {btnCancelText}
      </button>

      <Button
        onClick={btnOkAction}
        className="px-[24px] py-[10.25px] border border-red-10 bg-red-10 rounded-[6px] text-white text-[16px] font-medium leading-[20px] tracking-wider"
        isLoading={btnOkIsLoading}
        text={btnOkText}
      />
    </div>
  );
}

export { ModalFooter };

export default memo(Modal);
