import * as Sentry from "@sentry/react";
import useQueryData from "hooks/useQueryData";
import { MobileError } from "partials";
import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAccountService from "services/account.service";
import useUserService from "services/user.service";
import { clearStorage } from "utils";

interface DashboardContextInterface {}

const DashboardContext = createContext<DashboardContextInterface>(
  {} as DashboardContextInterface
);

/**
 * This provider act as a wrapper to check if the user is authenticated
 * moves to the dashboard screen if authenticated
 * moves to the login screen is unauthenticated.
 * default - dashboard screen
 */

function DashboardProvider() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const { getUser } = useUserService();
  const { getAccounts, getProjectsByAccount } = useAccountService();
  const { getUserData, getAccountData, getProjectData } = useQueryData();
  const { hash, pathname, hostname } = window.location;

  const redirect = encodeURIComponent(`${pathname}${hash}`);

  const redirectToLogin = () => {
    setIsLoggedIn(false);
    clearStorage();

    return navigate(`/login?redirect=${redirect}`);
  };

  const getInfo = async () => {
    const accountId = getAccountData()?.id;
    const projectId = getProjectData()?.id;

    if (!accountId) {
      await getAccounts();
    }
    if (!projectId) {
      await getProjectsByAccount();
    }
  };

  const handleLoginSuccess = async () => {
    await getInfo();
    setIsLoggedIn(true);

    return true;
  };

  const checkIfLogin = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const inDemoMode = hostname.includes("demo.getre.io");

      if (!inDemoMode && !token) {
        return redirectToLogin();
      }

      const email = getUserData()?.email;
      if (email) {
        return handleLoginSuccess();
      }

      const result = await getUser();

      if (!result || !result?.data?.email) {
        return redirectToLogin();
      } else {
        Sentry.setUser({ email: result?.data?.email });
        return handleLoginSuccess();
      }
    } catch (error) {
      return redirectToLogin();
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 800) {
      // setIsLoading(false);
      setShowMobileWarning(true);
    } else {
      checkIfLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardContext.Provider value={{}}>
      {showMobileWarning ? <MobileError /> : <>{isLoggedIn && <Outlet />}</>}
    </DashboardContext.Provider>
  );
}

function useDashboard() {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error("useDashboard must be used within an DashboardProvider");
  }

  return context;
}

export { useDashboard, DashboardProvider };
