import { Button, Chip, Modal } from "components";
import { ModalFooter } from "components/Modal";
import useInvites from "hooks/useInvites";
import useListInput from "hooks/useListInput";
import { useInvite } from "providers";
import { useEffect, useState } from "react";
import PendingUser from "./PendingUser";
import TeamUser from "./TeamUser";

const InviteModal = () => {
  const {
    revokeUser,
    modalVisible,
    inviteView,
    toggleInviteView,
    toggleModal,
    deleteIsLoading,
    handleCustomCancelModal,
    handleDeleteInvite,
    handleCustomCloseModal,
  } = useInvite();

  const {
    value,
    items,
    error,
    onSubmit,
    handlePaste,
    handleChange,
    handleDelete: handleDeleteItem,
    postIsLoading,
    handleKeyDown,
  } = useListInput();

  const { isLoading, teams, invites, getInfo } = useInvites();

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postIsLoading, deleteIsLoading]);

  const [isDirty, setIsDirty] = useState<boolean>(false);

  const customHandleChange = (e: any) => {
    setIsDirty(true);
    return handleChange(e);
  };

  const customHandleDelete = (item: any) => {
    setIsDirty(false);
    return handleDeleteItem(item);
  };

  function InviteModalContent() {
    return (
      <>
        {inviteView === "invite" && (
          <>
            <form onSubmit={onSubmit}>
              <div className="flex items-start gap-[15px]">
                <div className="relative border border-[#DEE0EA] p-2 rounded-[7px] flex flex-wrap items-center flex-grow gap-2 min-h-[47px]">
                  <div className="flex flex-wrap items-center gap-2">
                    {items.map((item) => (
                      <Chip
                        key={item}
                        title={item}
                        onDelete={() => customHandleDelete(item)}
                      />
                    ))}
                  </div>
                  <input
                    type="email"
                    multiple
                    className={`${
                      items?.length < 1 ? "w-3/5" : "min-w-[4rem]"
                    } text-[13px] leading-[15px] focus-visible:outline-none resize-none`}
                    placeholder={
                      items?.length < 1
                        ? "Type e-mails, separate with a comma"
                        : undefined
                    }
                    autoFocus
                    onKeyDown={handleKeyDown}
                    onChange={customHandleChange}
                    onPaste={handlePaste}
                    value={value}
                  />

                  {isDirty && items?.length < 1 && (
                    <span className="absolute right-[15px] text-right text-[14px] leading-[18px] text-[#10101096]">
                      press enter to add
                    </span>
                  )}
                </div>

                {items?.length > 0 && (
                  <Button
                    text="Share"
                    isLoading={postIsLoading}
                    type="submit"
                    className="share-shadow bg-[#4A35A5] border border-white rounded-[10px] text-white font-medium text-[16px] leading-[20px] spacing-[0.8px] flex items-center px-[32px] py-[13.5px]"
                  />
                )}
              </div>
              {error && (
                <div
                  className="mt-[8px] text-[#EC4B4A] text-[14px] leading-[18px]"
                  role="alert"
                >
                  {error}
                </div>
              )}
            </form>

            <div className="my-[30px]">
              <h3 className="font-bold text-[#10101096] text-[11px] leading-[14px] mb-[15px]">
                People with access
              </h3>

              <div className="overflow-y-scroll pb-5">
                {isLoading && teams.length < 1 && (
                  <div className="mt-[10px] text-center w-full">
                    <div
                      className="spinner-border animate-spin inline-block w-6 h-6 border-2 rounded-full"
                      role="status"
                    />

                    <p>Loading...</p>
                  </div>
                )}
                {teams.length > 0 ? (
                  <>
                    {teams.map(
                      (team: Record<string, string>, index: number) => (
                        <TeamUser
                          key={team?.id || index}
                          name={team?.name}
                          email={team?.email}
                          index={index}
                        />
                      )
                    )}
                  </>
                ) : null}

                {invites.length > 0 ? (
                  <>
                    {invites.map((invite: Record<string, string>) => (
                      <PendingUser
                        id={invite.id}
                        key={invite.id}
                        email={invite.email}
                        toggleInviteView={toggleInviteView}
                      />
                    ))}
                  </>
                ) : null}
              </div>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[11px] leading-[14px] text-[#10101096]">
                Invited people will have access to all reports in the workspace
              </p>
              <button
                className="rounded-[10px] border border-[#4A35A533] text-[#4A35A5] w-[111px] h-[47px] font-bold text-[16px] leading-[20px] flex items-center justify-center"
                onClick={toggleModal}
              >
                Done
              </button>
            </div>
          </>
        )}
        {inviteView === "revoke" && (
          <div className="flex items-center flex-col">
            <p className="whitespace-pre-line text-center pb-[25px] text-[16px] leading-[25px] tracking-wider text-black-10">
              Are you sure you want to remove{" "}
              <span className="font-semibold">{revokeUser?.email}</span> from
              the team? They will lose access to all the workspace.
            </p>

            <ModalFooter
              btnCancelText="Cancel"
              btnCancelAction={handleCustomCancelModal}
              btnOkText="Revoke"
              btnOkAction={handleDeleteInvite.bind(null, revokeUser?.id || "")}
              btnOkIsLoading={deleteIsLoading}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <Modal
      modalVisible={modalVisible}
      title={
        inviteView === "invite" ? "Invite a new team member" : "Revoke access"
      }
      closeModal={handleCustomCloseModal}
      modalContent={<InviteModalContent />}
    />
  );
};

export default InviteModal;
