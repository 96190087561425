import { memo } from "react";
import { isStringArray } from "utils";
import "./select.css";

interface SelectProps {
  options: string[] | Record<string, string>[];
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showOptionLabel?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

function Select(props: SelectProps) {
  const {
    defaultValue,
    disabled,
    handleChange,
    options,
    placeholder,
    showOptionLabel = true,
    value,
    isLoading,
  } = props;
  return (
    <div className="select-container relative w-1/4" data-testid="test-Select">
      <select
        className="form-select appearance-none block w-full px-2 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:shadow-none focus:primary focus:outline-none"
        aria-label="Upload options"
        onChange={handleChange}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {showOptionLabel && (
          <option aria-disabled disabled selected>
            {placeholder || "Report type"}
          </option>
        )}
        {isStringArray(options) ? (
          <>
            {options.map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </>
        ) : (
          <>
            {options.map(({ key, value: v }: Record<string, string>) => (
              <option key={key} value={v}>
                {key}
              </option>
            ))}
          </>
        )}
      </select>

      {isLoading && (
        <div
          className={`flex justify-center items-center absolute top-[6px] right-[9px] z-30 text-gray-600 ${
            disabled ? "bg-[#ebeff1]" : "bg-white"
          }`}
        >
          <div
            className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Select);
