import useQueryData from "hooks/useQueryData";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import useAccountService from "services/account.service";
import useUserService from "services/user.service";
import { QueryClientType } from "utils";

function useBilling() {
    const queryClient = useQueryClient();
    const { pathname } = useLocation();

    const { getUser } = useUserService();
    const { getAccountData } = useQueryData();
    const { getAccounts, getAccountBilling, isLoading } = useAccountService();

    const [billingInfo, setBillingInfo] = useState<null | Record<string, any>>(
        null
    );

    const getBilling = async () => {
        const billingData = queryClient.getQueryData("get-account-billing") as QueryClientType;

        if (pathname === '/settings/billing' || !billingData?.data) {
            const result = await getAccountBilling();

            setBillingInfo(result.data);
        } else {
            setBillingInfo(billingData.data);
        }
    };

    const subscriptionEndDate = billingInfo?.subscription?.current_period_end * 1000;

    const nextBillingDate = useMemo(() => {
        if (!subscriptionEndDate) {
            return null;
        }

        const endDate = subscriptionEndDate as number;
        const date = new Date(endDate);

        return new Intl.DateTimeFormat("en", {
            month: "short",
            day: "2-digit",
            year: "numeric"
        }).format(
            date
        );
    }, [subscriptionEndDate]);

    useEffect(() => {
        getBilling();
        getAccounts();
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleManageSubscription = () => {
        window.open(billingInfo?.portal_session?.url, "_blank");
        return true;
    };

    const accountSubscriptionStatus = getAccountData()?.stripe_subscription_status;
    const trialDaysLeft = getAccountData()?.trial_days_left as unknown as number;
    const hasPaymentMethod = !!(billingInfo?.subscription?.customer?.invoice_settings?.default_payment_method);

    const daysLeftCheck = trialDaysLeft
        ? trialDaysLeft < 15
        : false;

    const hasEndedTrial = trialDaysLeft <= 0;

    const endTrialModal =
        ["active", "past_due"].includes(accountSubscriptionStatus as unknown as string)
            ? false
            : !!(((accountSubscriptionStatus === null) && hasEndedTrial) || ['canceled', "incomplete"].includes(accountSubscriptionStatus));

    const hasPartialAccess = accountSubscriptionStatus === "past_due";

    const incompleteSubscription = accountSubscriptionStatus === "incomplete";

    const hasPreviousSubscription =
        accountSubscriptionStatus === "active" || hasPartialAccess || incompleteSubscription;

    return {
        isLoading,
        billingInfo,
        hasEndedTrial,
        endTrialModal,
        daysLeftCheck,
        trialDaysLeft,
        nextBillingDate,
        hasPartialAccess,
        hasPaymentMethod,
        incompleteSubscription,
        hasPreviousSubscription,
        handleManageSubscription,
        accountSubscriptionStatus,
        currentSubscriptionPrice: 100,
        currentSubscriptionCurrency: "USD",
        showBillingInfo: !!billingInfo?.portal_session?.url,
    };
}

export default useBilling;