import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import useAccountService from "./account.service";
import { api, API_ROUTES, CatchErrorType, clearStorage, displayErrors, isArray, toast } from "utils";

const useUserService = () => {
  const { mutateAsync, isLoading } = useMutation(api.post);
  const { mutateAsync: putMutateAsync, isLoading: putIsLoading } = useMutation(api.put);
  const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
  const { getAccounts, getProjectsByAccount } = useAccountService();

  const login = async (values: Record<string, string>) => {
    try {
      const { access_token, data, message, status } = await mutateAsync([API_ROUTES.LOGIN, values]);

      if (status !== "error") {
        localStorage.setItem('access_token', access_token);
        await getAccounts();
        await getProjectsByAccount();
        return data;
      }

      toast(message, "error");
      return false;
    } catch (error) {
      displayErrors(error as CatchErrorType);
      return false;
    }
  };

  const sso = async (values: Record<string, string>) => {
    try {
      const { access_token, data, message, status } = await mutateAsync([API_ROUTES.SSO, values]);

      if (status !== "error") {
        localStorage.setItem('access_token', access_token);
        await getAccounts();
        await getProjectsByAccount();
        return data;
      }

      toast(message, "error");
      return false;
    } catch (error) {
      displayErrors(error as CatchErrorType);
      return false;
    }
  };

  const logout = async () => {
    try {
      const { message } = await mutateAsync([API_ROUTES.LOGOUT]);

      toast(message, "success");
      return false;
    } catch (error) {
      displayErrors(error as CatchErrorType);
      return false;
    } finally {
      clearStorage();
    }
  };

  const register = async (values: Record<string, string>) => {
    try {
      const response = await mutateAsync([API_ROUTES.REGISTER, values]);
      const { data, message, status } = response;

      if (status !== "error") {
        if ("access_token" in response) {
          localStorage.setItem('access_token', response.access_token);
          await getAccounts();
          await getProjectsByAccount();
        }

        toast(message, "success");
        return data;
      }
      toast(message, "error");
      return false;
    } catch (error) {
      displayErrors(error as CatchErrorType);
      return false;
    }
  };

  const verifyEmail = async (values: Record<string, string>) => {
    try {
      const { data, message, status } = await mutateAsync([API_ROUTES.VERIFY_EMAIL, values]);
      if (status !== "error") {
        toast(message, "success");
        return data;
      }
      toast(message, "error");
      return false;
    } catch (error) {
      displayErrors(error as CatchErrorType);
      return false;
    }
  };

  const resendVerifyEmail = async () => {
    try {
      const { data, message, status } = await putMutateAsync([API_ROUTES.VERIFY_EMAIL]);

      if (status !== "error") {
        toast(message, "success");
        return data;
      }
      toast(message, "error");
      return false;
    } catch (error: unknown) {
      const err = error as AxiosError;
      const message = err?.message;
      if (!isArray(message)) {
        toast(message, "error");
      } else {
        displayErrors(error as CatchErrorType);
      }

      return false;
    }
  };

  const changePassword = async (values: Record<string, string>) => {
    try {
      const { data, message, status } = await mutateAsync([API_ROUTES.CHANGE_PASSWORD, values]);

      if (status !== "error") {
        toast(message, "success");
        return data;
      }
      toast(message, "error");
      return false;
    } catch (error: unknown) {
      const err = error as AxiosError;
      const message = err?.message;
      if (!isArray(message)) {
        toast(message, "error");
      } else {
        displayErrors(error as CatchErrorType);
      }

      return false;
    }
  };

  const forgotPassword = async (values: Record<string, string>) => {
    try {
      const { data, message, status } = await mutateAsync([API_ROUTES.FORGOT_PASSWORD, values]);

      if (status !== "error") {
        toast(message, "success");
        return data;
      }
      toast(message, "error");
      return false;
    } catch (error: unknown) {
      const err = error as AxiosError;
      const message = err?.message;
      if (!isArray(message)) {
        toast(message, "error");
      } else {
        displayErrors(error as CatchErrorType);
      }

      return false;
    }
  };

  const resetPassword = async (values: Record<string, string>) => {
    try {
      const { data, message, status } = await putMutateAsync([API_ROUTES.RESET_PASSWORD, values]);

      if (status !== "error" && data) {
        toast(message, "success");
        return data;
      }
      toast(message, "error");
      return false;
    } catch (error: unknown) {
      const err = error as AxiosError;
      const message = err?.message;
      if (!isArray(message)) {
        toast(message, "error");
      } else {
        displayErrors(error as CatchErrorType);
      }

      return false;
    }
  };

  const getUserApi = useQuery({
    queryKey: 'user',
    queryFn: () => api.get(API_ROUTES.GET_USER),
    enabled: false,
    retry: 0
  });

  const getUser = async () => {
    try {
      setIsGetLoading(true);
      const { data, status } = await getUserApi.refetch();

      if (status !== "error" && data) {
        localStorage.setItem('user', JSON.stringify(data.data));

        return data;
      }

      return false;
    } catch (error) {
      displayErrors(error as CatchErrorType);
      return false;
    } finally {
      setIsGetLoading(false);
    }
  };

  return {
    isLoading: putIsLoading || isLoading || isGetLoading,

    sso,
    login,
    logout,
    register,
    verifyEmail,
    resendVerifyEmail,
    changePassword,
    forgotPassword,
    resetPassword,
    getUser,
  };
};

export default useUserService;
