import search from "images/search.svg";
import {
  ClassAttributes,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactNode,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CellValue,
  ColumnInstance,
  Row,
  TableInstance,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Pagination, { PaginationProps } from "./Pagination";
import "./Table.css";

type ColumnType = ColumnInstance<Record<string, string>> & { type: string };
export interface CellProps {
  column?: ColumnType;
  row: Row<Record<string, string>>;
  value: CellValue<string>;
}
export interface ColumnsProps {
  Header: any;
  accessor: string;
  Cell?: ({ value, column, row }: CellProps) => JSX.Element;
  type?: string;
  width?: number;
  align?: string;
  hasSort?: boolean;
}

export interface TableProps {
  columns: any[];
  data: Record<string, any>[];
  showSearch?: boolean;
  LeftComponent?: React.FunctionComponent<any> | null;
  RightComponent?: React.FunctionComponent<any> | null;
  isLoading?: boolean;
  EmptyData?: ReactNode;
  pagination?: PaginationProps;
  handlePageNumberClick?: ((pageNumber: number) => void) | undefined;
}

interface IFilter {
  globalFilter: string;
  setGlobalFilter: (v?: string) => void;
  total?: number;
}

const CustomFilter = memo(
  forwardRef(({ globalFilter, setGlobalFilter }: IFilter, ref: any) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((val: string) => {
      setGlobalFilter(val || undefined);
    }, 200);

    return (
      <label className="relative flex overflow-hidden gap-x-2 items-center w-1/5 h-[34px] rounded-md border border-gray-20">
        <img src={search} alt="search" className="absolute ml-2 w-4 h-4" />
        <input
          ref={ref}
          type="text"
          className="w-full h-full pl-8 pr-2 py-1 text-sm text-black-10 opacity-[59] placeholder-current focus:border-none focus:ring-none focus-visible:outline-none"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            return onChange(e.target.value);
          }}
          placeholder="Search for upload"
        />
      </label>
    );
  })
);

function Table(props: TableProps, ref: Ref<unknown> | undefined) {
  const {
    columns,
    data,

    showSearch = true,
    LeftComponent = null,
    RightComponent = null,
    isLoading,
    EmptyData,
    pagination,
    handlePageNumberClick,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
  }: TableInstance = useTable(
    {
      columns,
      data,
      manualPagination: pagination !== undefined,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const check = useMemo(
    () =>
      showSearch === false && RightComponent === null && LeftComponent === null,
    [showSearch, RightComponent, LeftComponent]
  );

  const styledPadding = check ? "px-2" : "px-6";

  const searchRef = useRef(undefined);
  const searchRefCurrent = searchRef.current as any;
  useImperativeHandle(ref, () => ({
    resetSearch() {
      if (searchRefCurrent && searchRefCurrent.value) {
        searchRefCurrent.value = "";

        setTimeout(() => {
          setGlobalFilter("");
        }, 400);
      }
    },
  }));

  return (
    <>
      {(showSearch || RightComponent || LeftComponent) && (
        <div
          className={`flex justify-between items-center pt-2 ${
            LeftComponent && showSearch ? "mb-10" : "mb-3"
          }`}
        >
          {LeftComponent && <LeftComponent />}

          {showSearch && (
            <CustomFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              total={pagination?.total}
              ref={searchRef}
            />
          )}

          {RightComponent && <RightComponent />}
        </div>
      )}

      <table
        {...getTableProps()}
        className={`w-full table-auto ${isLoading ? "table-loading" : ""} ${
          columns.length === 3 ? "flex flex-col" : ""
        } divide-y divide-gray-200 rounded`}
      >
        <thead className="bg-gray-50">
          {headerGroups.map(
            (headerGroup: {
              getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                ClassAttributes<HTMLTableRowElement> &
                HTMLAttributes<HTMLTableRowElement>;
              headers: any[];
            }) => (
              <tr
                className={columns.length === 3 ? "flex-1 flex" : ""}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className={`${styledPadding} ${
                      typeof column?.Header === "string" &&
                      column?.Header?.toLowerCase() === "name"
                        ? "w-5/12 text-left"
                        : "w-1/5 text-center"
                    } py-3 text-xs font-medium text-gray-500 uppercase tracking-wider`}
                    {...column.getHeaderProps(
                      column?.hasSort !== false
                        ? column.getSortByToggleProps()
                        : undefined
                    )}
                  >
                    <div className="group flex items-center justify-between">
                      {column.render("Header")}

                      {column?.hasSort !== false && (
                        <span>
                          {column.isSorted ? (
                            <>
                              {column.isSortedDesc ? (
                                <span className="ml-2 text-gray-400">↓</span>
                              ) : (
                                <span className="ml-2 text-gray-400">↑</span>
                              )}
                            </>
                          ) : (
                            <span className="ml-2 text-gray-400 opacity-0 group-hover:opacity-100">
                              ↕
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            )
          )}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200"
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr className="relative" {...row.getRowProps()} key={row?.id}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={`${styledPadding} ${
                      cell?.column?.id?.toLowerCase() === "name"
                        ? "w-5/12 truncate"
                        : "w-1/5"
                    } py-4 text-sm`}
                    role="cell"
                    title={cell.value}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}

          {page.length === 0 && isLoading && (
            <>
              {new Array(10).fill({}).map((_, i) => (
                <tr key={i}>
                  {columns.map((_, index) => (
                    <td
                      key={index}
                      className={`${styledPadding} loading-td ${
                        columns.length === 3 ? "w-1/3" : "w-1/5"
                      } py-4 text-sm`}
                      role="cell"
                    >
                      <div>&nbsp;</div>
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>

      {page.length < 1 && !isLoading && EmptyData}

      {page.length > 0 && (
        <div className="flex items-center justify-between border-t border-gray-200">
          <div className="flex-1 flex justify-between sm:hidden pt-4">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              type="button"
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>

          {pageOptions.length > 1 && (
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-6">
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  Page
                  <span className="font-medium px-1">{pageIndex + 1}</span>
                  of
                  <span className="font-medium px-1">{pageOptions.length}</span>
                </p>
                <label>
                  <span className="sr-only">Items Per Page</span>
                  <select
                    className="mt-1 block w-full ml-3 px-2 py-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 50, 100].map((size: number) => (
                      <option key={size} value={size}>
                        {`Show ${size}`}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <button
                  type="button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </nav>
            </div>
          )}
        </div>
      )}

      {page.length > 0 && handlePageNumberClick && (
        <Pagination
          {...(pagination || undefined)}
          handlePageNumberClick={handlePageNumberClick}
        />
      )}
    </>
  );
}

export default memo(forwardRef(Table));
