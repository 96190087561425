import { useInvite } from "providers";
import { useState } from "react";
import useInviteService from "services/invite.service";
import { PendingUserType } from "utils";

interface InviteOptionInterface extends PendingUserType {
  from?: "nav" | "settings" | null;
}

function InviteOption({ id, email, from }: InviteOptionInterface) {
  const {
    revokeUser,
    setInviteView,
    setRevokeUser,
    setModalVisible,
    deleteIsLoading,
    setClickedFrom,
  } = useInvite();

  const { resendInviteEmail, postIsLoading } = useInviteService();

  const [optionIsVisible, setOptionIsVisible] = useState<boolean>(false);

  const toggleOption = () => setOptionIsVisible((x) => !x);

  const handleResendRequest = async () => {
    await resendInviteEmail({}, revokeUser?.id || "");
    toggleOption();

    setRevokeUser(null);
    setInviteView("invite");
  };

  const handleRevokeAccess = () => {
    setRevokeUser({ id, email });
    toggleOption();
    setClickedFrom(from || null);
    setInviteView("revoke");
    setModalVisible(true);
  };

  return (
    <>
      <button onClick={toggleOption}>
        <svg
          width={18}
          height={18}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="option-btn"
        >
          <path
            d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <ul
        className={`dropdown-shadow absolute ${
          optionIsVisible ? "flex" : "hidden"
        } bg-white z-10 ${
          from === "settings"
            ? "top-[50px] right-[20px]"
            : "top-[20px] right-[5px]"
        } min-w-[162px] py-[18px] pl-[24px] flex-col gap-[24px]`}
      >
        <li onClick={handleRevokeAccess} className="cursor-pointer">
          {deleteIsLoading ? (
            <div className="flex items-center">
              <span className="mr-2">Loading...</span>
              <div
                className="spinner-border animate-spin inline-block w-4 h-4 text-primary border-2 rounded-full"
                role="status"
              />
            </div>
          ) : (
            <span className="text-[16px] leading-[20px] font-medium text-[#10101096]">
              Revoke access
            </span>
          )}
        </li>

        <li onClick={handleResendRequest} className="cursor-pointer">
          {postIsLoading ? (
            <div className="flex items-center">
              <span className="mr-2">Loading...</span>
              <div
                className="spinner-border animate-spin inline-block w-4 h-4 text-red-500 border-2 rounded-full"
                role="status"
              />
            </div>
          ) : (
            <span className="text-[16px] leading-[20px] font-medium text-[#10101096]">
              Resend invite
            </span>
          )}
        </li>
      </ul>
    </>
  );
}

export default InviteOption;
