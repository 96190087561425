import { memo } from "react";

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  text?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  className?: string;
}

function Button(props: ButtonProps) {
  const {
    type = "button",
    text,
    icon,
    disabled,
    isLoading,
    onClick,
    className,
  } = props;

  return (
    <button
      data-testid="test-Button"
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      className={
        className ||
        "inline-block tracking-[0.66px] w-full px-7 py-3 bg-primary-10 text-white font-bold text-xs leading-snug uppercase rounded shadow-md disabled:bg-primary2 hover:bg-primary2 hover:shadow-lg focus:bg-primary2 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out"
      }
    >
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div
            className="spinner-border animate-spin inline-block w-6 h-6 border-2 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          className={
            icon ? `flex gap-3 items-center justify-center` : undefined
          }
        >
          {icon}

          {text}
        </div>
      )}
    </button>
  );
}

export default memo(Button);
