import { Avatar } from "components";
import { InviteOption } from "partials/Invite";

function PendingUser({ email, id }: any) {
  return (
    <div className="flex items-center mb-[20px]">
      <div className="flex flex-1 gap-[10px]">
        <Avatar isPending name={email} />

        <div className="flex items-center">
          <p className="text-[14px] leading-[18px] text-[#10101096]">{email}</p>
        </div>
      </div>
      <div className="flex items-center gap-[10px] relative">
        <p className="text-[14px] leading-[18px] text-[#10101096]">pending</p>

        <InviteOption email={email} id={id} from="nav" />
      </div>
    </div>
  );
}

export default PendingUser;
