import email from "images/email.svg";
import { useCallback } from "react";
import useUserService from "services/user.service";

import useQueryData from "hooks/useQueryData";

function VerifyEmail({ skipOnClick, showSkip = true }: any): JSX.Element {
  const { getUserData } = useQueryData();

  const user = getUserData();
  const { resendVerifyEmail, isLoading } = useUserService();

  const handleResendRequest = useCallback(async () => {
    return await resendVerifyEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center flex-col text-center text-black-10">
      <img
        loading="lazy"
        src={email}
        alt="Email Icon"
        className="h-[100px] w-[174px] mt-7"
      />

      <p className="mt-12 tracking-[0.06rem]">
        We have sent a verification email to <b>{user?.email}</b>. Click the
        link inside to complete your signing up.
      </p>

      <p className="mt-7 mb-10 text-xs tracking-[0.06rem]">
        Didn't get the email? Check Spam. Still nothing?
        <button
          className="text-primary cursor-pointer mx-1"
          disabled={isLoading}
          onClick={handleResendRequest}
        >
          Resend
        </button>
        {isLoading && (
          <div
            className="inline-block h-3 w-3 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        )}
      </p>

      {showSkip && (
        <button
          onClick={skipOnClick}
          className="text-primary text-base text-center leading-6 tracking-[0.06rem] font-bold "
        >
          Skip for now
        </button>
      )}
    </div>
  );
}

export { VerifyEmail };
