import logo from "images/redata-square.svg";

function MobileError() {
  return (
    <div className="bg-gray-10 w-full h-screen p-10 flex flex-col items-center justify-center">
      <img src={logo} className="h-14 w-auto" alt="logo" loading="lazy" />

      <div className="text-red-500 text-center font-semibold mt-8">
        You need a desktop computer to view this application
      </div>
    </div>
  );
}

export { MobileError };
