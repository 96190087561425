enum API_ROUTES {
    BASE_URL = "https://re-cloud-staging-tuywl55uaa-ew.a.run.app/api/",
    LOGIN = "user/login",
    SSO = "user/sso",
    LOGOUT = "user/logout",
    REGISTER = "user",
    GET_USER = "user",
    CHANGE_PASSWORD = "user/change_password",
    FORGOT_PASSWORD = "user/reset_password",
    RESET_PASSWORD = "user/reset_password",
    VERIFY_EMAIL = "user/email_verification",
    GET_ACCOUNTS = "account",
    GET_ACCOUNTS_TEAM_MEMBER = "/account/:accountId/teams",
    GET_PROJECTS_BY_ACCOUNTS = "account/:accountId/projects",
    GET_ACCOUNT_BILLING = "account/:accountId/billing",
    SUBSCRIBE = "account/:accountId/subscribe",
    GET_UPLOADS_BY_ACCOUNTS_AND_PROJECTS = "account/:accountId/:projectId/uploads",
    GET_API_KEY_BY_ACCOUNT = "account/:accountId/apikey",
    GET_UPLOADS_STATS = 'uploads/:projectId/stats',
    DELETE_UPLOADS = "uploads/:uploadId",
    SLACK_INSTALL = "slack/install",
    SLACK_REVOKE = "slack/revoke",
    SLACK_CHANNELS = "slack/channels",
    SLACK_SETTINGS = "slack/settings",
    INVITE_EMAIL = "account/:accountId/invites",
    RESEND_INVITE_EMAIL = "account/:accountId/invites/:inviteId/resend",
    GET_UPLOAD_DATA = "uploads/:uploadId/?json=true",
}

export { API_ROUTES };
