import { LoadingLogo } from "components";
import { AnimatePresence } from "framer-motion";
import {
  AuthProvider,
  DashboardProvider,
  InviteProvider,
  UploadProvider,
} from "providers";
import { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "tw-elements";
import { lazyRetry, loadingDelay } from "utils";
import { GoogleOAuthProvider } from '@react-oauth/google';

const Login = lazy(() => lazyRetry(() => loadingDelay(import("pages/Login")), "login"));

const Register = lazy(() => lazyRetry(() => loadingDelay(import("pages/Register")), "register"));
const ForgotPassword = lazy(() => lazyRetry(() => loadingDelay(import("pages/ForgotPassword")), "forgotPassword"));
const ResetPassword = lazy(() => lazyRetry(() => loadingDelay(import("pages/ResetPassword")), "resetPassword"));
const VerifyEmail = lazy(() => lazyRetry(() => loadingDelay(import("pages/VerifyEmail")), "verifyEmail"));

const Dashboard = lazy(() => lazyRetry(() => loadingDelay(import("pages/Dashboard")), "dashboard"));
const History = lazy(() => lazyRetry(() => loadingDelay(import("pages/History")), "history"));
const Uploads = lazy(() => lazyRetry(() => loadingDelay(import("pages/Uploads"), 100), "uploads"));
const Settings = lazy(() => lazyRetry(() => loadingDelay(import("pages/Settings")), "settings"));

function App() {
  const location = useLocation();
  return (
    <GoogleOAuthProvider clientId="451342595350-hsuotvo2ohemnuqo132aevvh1spif5rd.apps.googleusercontent.com">
      <Suspense fallback={<LoadingLogo />}>
        <UploadProvider>
          <InviteProvider>
            <AnimatePresence mode="wait">
              <Routes key={location.pathname} location={location}>
                <Route element={<AuthProvider />}>
                  <Route path="/" element={<Login />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="verify" element={<VerifyEmail />} />
                </Route>

                <Route element={<DashboardProvider />}>
                  <Route path="verify" element={<VerifyEmail />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="dashboard/history" element={<History />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="settings/:page" element={<Settings />} />
                  <Route path="uploads" element={<Uploads />} />
                </Route>
              </Routes>
            </AnimatePresence>
          </InviteProvider>
        </UploadProvider>
      </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
