
import { useQueryClient } from "react-query";
import { QueryClientType, QueryClientType2, clearStorage } from "utils";

function useQueryData() {
    const queryClient = useQueryClient();

    const getAccountData = () => {
        const accountData = queryClient.getQueryData("accounts") as QueryClientType;
        const accountSession = JSON.parse(localStorage.getItem("accounts") ?? "null");

        return accountData?.data || accountSession;
    }

    const getProjectData = (): Record<string, any> => {
        const projectData = queryClient.getQueryData("get-projects-by-accounts") as QueryClientType2;
        const projectSession = JSON.parse(localStorage.getItem("projects") ?? "null");

        return projectData?.data?.[0] || projectSession?.[0];
    }

    const getUserData = (): Record<string, any> | null => {
        const userData = queryClient.getQueryData("user") as QueryClientType2;
        const localUser = localStorage.getItem("user") ?? "null";
        let userSession;

        if (localUser && localUser !== "undefined") {
            userSession = JSON.parse(localUser);
        } else {
            clearStorage();
            return null;
        }

        return userData?.data || userSession;
    };

    return {
        getUserData,
        getAccountData,
        getProjectData,
    };
}

export default useQueryData;