import { memo } from "react";
import { generateColor } from "utils";
import "./avatar.css";

interface AvatarProps {
  name?: string;
  random?: boolean;
  index?: number;
  isPending?: boolean;
  isSmall?: boolean;
}

function Avatar(props: AvatarProps) {
  const { name, random, index, isPending = false, isSmall = false } = props;

  const bgColor =
    random && index !== undefined ? generateColor(index) : "blue-10";

  return (
    <>
      {!isPending ? (
        <div
          className={`${
            isSmall ? "w-[24px] h-[24px]" : "w-[40px] h-[40px]"
          } flex items-center justify-center text-white bg-${bgColor} rounded-[7px]`}
          data-testid="test-Avatar"
        >
          <p
            className={`${
              random
                ? "font-bold text-[25px] leading-[32px]"
                : isSmall
                ? "font-medium text-[14px] leading-[19px]"
                : "font-medium text-[17px] leading-[25px]"
            }`}
          >
            {name?.charAt(0)}
          </p>
        </div>
      ) : (
        <div className="rounded-[7px] border border-dashed border-[#10101096] w-[40px] h-[40px] flex justify-center items-center">
          <h2 className="font-medium text-[#10101096] text-[30px] leading-[38px]">
            {name?.charAt(0)?.toUpperCase()}
          </h2>
        </div>
      )}
    </>
  );
}

export default memo(Avatar);
