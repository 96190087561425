import { Avatar } from "components";

type TeamUserProps = {
  name: string;
  email: string;
  index?: number;
};

function TeamUser({ name, email, index }: TeamUserProps) {
  return (
    <div className="flex items-center mb-[20px]">
      <div className="flex flex-1 gap-[10px]">
        <Avatar random={true} index={index} name={name} />
        <div>
          <h4 className="text-black-10 text-[16px] leading-[20px] mb-[5px]">
            {name}
          </h4>
          <p className="text-[14px] leading-[18px] text-[#10101096]">{email}</p>
        </div>
      </div>
    </div>
  );
}

export default TeamUser;