import useQueryData from "hooks/useQueryData";
import { useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { api, API_ROUTES, CatchErrorType, displayErrors, toast } from "utils";

/**
 * All api that has to do with the account service
 * i.e services that communicates with the account & subscription apis
 */

const useAccountService = () => {
    const { getAccountData } = useQueryData();
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: postMutateAsync, isLoading: postIsLoading } = useMutation(api.post);

    const getAccountsApi = useQuery({
        queryKey: 'accounts',
        queryFn: () => api.get(API_ROUTES.GET_ACCOUNTS),
        enabled: false,
        retry: 0
    });

    const getAccounts = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await getAccountsApi.refetch();

            if (status !== "error" && data) {
                localStorage.setItem('accounts', JSON.stringify(data.data));

                return data;
            }
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const getProjectsByAccountApi = useQuery({
        queryKey: 'get-projects-by-accounts',
        queryFn: () => {
            const accountId = getAccountData()?.id;
            const url = API_ROUTES.GET_PROJECTS_BY_ACCOUNTS.replace(':accountId', accountId);

            return api.get(url);
        },
        enabled: false,
        retry: 0
    });

    const getProjectsByAccount = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await getProjectsByAccountApi.refetch();

            if (status !== "error" && data) {
                localStorage.setItem('projects', JSON.stringify(data.data));

                return data;
            }
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const getAccountBillingApi = useQuery({
        queryKey: 'get-account-billing',
        queryFn: () => {
            const accountId = getAccountData()?.id;
            const url = API_ROUTES.GET_ACCOUNT_BILLING.replace(':accountId', accountId);

            return api.get(url);
        },
        enabled: false,
        retry: 0
    });

    const getAccountBilling = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await getAccountBillingApi.refetch();

            if (status !== "error" && data) {
              return data;
            }
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const subscribe = async () => {
        try {
            const accountId = getAccountData()?.id;
            const url = API_ROUTES.SUBSCRIBE.replace(':accountId', accountId);

            const { data, message, status } = await postMutateAsync([url, {}]);

            if (status !== "error" && data) {
                toast(message, "success");
                return data;
            }

            toast(message, "error");
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        }
    };

    const getApiKeyByAccountApi = useQuery({
        queryKey: 'get-api-key-by-account',
        queryFn: () => {
            const accountId = getAccountData()?.id;
            const url = API_ROUTES.GET_API_KEY_BY_ACCOUNT.replace(':accountId', accountId);

            return api.get(url);
        },
        enabled: false,
        retry: 0,
        staleTime: 3000,
    });

    const getApiKeyByAccount = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await getApiKeyByAccountApi.refetch();

            if (status !== "error" && data) {
              return data;
            }
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const getTeamByAccountApi = useQuery({
        queryKey: 'get-teams-by-account',
        queryFn: () => {
            const accountId = getAccountData()?.id;
            const url = API_ROUTES.GET_ACCOUNTS_TEAM_MEMBER.replace(':accountId', accountId);

            return api.get(url);
        },
        enabled: false,
        retry: 0
    });

    const getTeamByAccount = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await getTeamByAccountApi.refetch();

            if (status !== "error" && data) {
              return data;
            }
            return false;
        } catch (error) {
            displayErrors(error as CatchErrorType);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        postIsLoading,

        subscribe,
        getAccounts,
        getAccountBilling,
        getApiKeyByAccount,
        getProjectsByAccount,
        getTeamByAccount,
    };
};

export default useAccountService;
