import * as Sentry from "@sentry/react";
import { RouterHistory } from "@sentry/react/types/reactrouter";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSentryEnvironment } from "utils";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./wdyr";

const sentryEnvironment = getSentryEnvironment();

const history = createBrowserHistory();

// remove sentry logging from local dev
if (sentryEnvironment !== "local") {
  Sentry.init({
    dsn: "https://18276c592f904f40ac283bb12800eaf7@o4504678215712768.ingest.sentry.io/4504678232096768",

    replaysSessionSampleRate: sentryEnvironment === "production" ? 0.3 : 1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history as unknown as RouterHistory
        ),
      }),
      new Sentry.Replay(),
    ],

    environment: sentryEnvironment,
    tracesSampleRate: 1.0,

    release: sentryEnvironment,
  });
}

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </QueryClientProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
