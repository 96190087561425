import { memo } from "react";
import "./toggle.css";

interface ToggleProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  leftLabel?: string;
  title?: string;
  rightLabel?: string;
  value?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

function Toggle(props: ToggleProps): JSX.Element {
  const { onChange, value, isLoading, disabled, title } = props;

  return (
    <div
      className="flex items-center justify-center w-full"
      data-testid="test-Toggle"
    >
      <div
        className={`form-check form-switch ${
          disabled ? "cursor-not-allowed" : ""
        }`}
        title={title}
      >
        <input
          className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
          type="checkbox"
          role="switch"
          disabled={disabled}
          checked={value}
          onChange={onChange}
        />
      </div>
      {isLoading && (
        <div
          className="flex justify-center items-center z-30 ml-3 text-gray-600"
        >
          <div
            className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Toggle);
