/* eslint-disable no-useless-escape */
import { ToastContent, TypeOptions, toast as toastify } from "react-toastify";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

import custom from "images/custom.svg";
import dbt from "images/dbt.svg";
import elementary from "images/elementary.svg";
import great_expectations from "images/great_expectations.svg";
import html_folder from "images/html-folder.svg";
import html from "images/html.svg";
import jupyter from "images/jupyter_notebook.svg";
import markdown from "images/markdown.svg";
import pandas_profiling from "images/pandas_profiling.png";
import redata from "images/redata-square.svg";

import negative_down from "images/negative-down.svg";
import negative_up from "images/negative-up.svg";
import positive_down from "images/positive-down.svg";
import positive_up from "images/positive-up.svg";
import { DateTimeType } from "utils/types";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const emailRegEx = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/;

export const uploadTypes = [
  "RE_DATA_OVERVIEW", "DBT_DOCS", "PANDAS_PROFILING", "GREAT_EXPECTATIONS",
  "ELEMENTARY", "JUPYTER_NOTEBOOK", "HTML_FILE", "CUSTOM", "HTML_FOLDER",
  "MARKDOWN"
] as const;

export type ImgUploadType = typeof uploadTypes[number];

const imageMap = new Map<ImgUploadType, any>();
imageMap.set("RE_DATA_OVERVIEW", redata);
imageMap.set("DBT_DOCS", dbt);
imageMap.set("PANDAS_PROFILING", pandas_profiling);
imageMap.set("GREAT_EXPECTATIONS", great_expectations);
imageMap.set("ELEMENTARY", elementary);
imageMap.set("JUPYTER_NOTEBOOK", jupyter);
imageMap.set("HTML_FILE", html);
imageMap.set("CUSTOM", custom);
imageMap.set("HTML_FOLDER", html_folder);
imageMap.set("MARKDOWN", markdown);

const arrowMap = new Map<string, any>();
arrowMap.set("POSITIVE_UP", positive_up);
arrowMap.set("POSITIVE_DOWN", positive_down);
arrowMap.set("NEGATIVE_UP", negative_up);
arrowMap.set("NEGATIVE_DOWN", negative_down);

export * from "./api";
export * from "./routes";
export * from "./types";
export { arrowMap, imageMap };

export const capitalize = (word: string) => {
  if (word === "" || !word) {
    return "";
  }

  word = word.toLowerCase();
  const [firstLetter, ...rest] = word;

  return [firstLetter?.toUpperCase(), ...rest]?.join("");
};

export const generateInputId = (label: string) => label.split(" ").join("-");

export const toast = (message: ToastContent, type?: TypeOptions) => toastify(message, { type });

export const displayErrors = (error: Record<string, string[]>) => {
  const errors = error?.message;
  if (isArray(errors)) {
    for (const err in errors) {
      const message = errors[err][0];

      toast(`${err}: ${message}`, "error");
    }
  }

  toast(`${errors}`, "error");
  return;
};

export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const copyToClipboard = (text?: string, message?: string): Promise<void> => {
  toast(message, 'success');
  return navigator.clipboard.writeText(text || '');
}

export const getCookie = (name: string): string => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts?.pop()?.split(';')?.shift() || '';
  }
  return '';
};

export const getDifferenceInDays = (date1: number, date2: number): number => {
  const diffInMs = Math.abs(date2 - date1);
  return parseInt((diffInMs / (1000 * 60 * 60 * 24))?.toString());
}

export const clearStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('from_user');
  localStorage.removeItem('user');
  localStorage.removeItem('accounts');
  localStorage.removeItem('projects');

  return true;
}

export const currencyToSymbol = (currency: string): string => {
  if (currency === "" || !currency) {
    return "";
  }

  currency = currency.toUpperCase();
  const currencySymbols = new Map<string, string>();

  currencySymbols.set('USD', '$');
  currencySymbols.set('EUR', '€');
  currencySymbols.set('CRC', '₡');
  currencySymbols.set('GBP', '£');
  currencySymbols.set('ILS', '₪');
  currencySymbols.set('INR', '₹');
  currencySymbols.set('JPY', '¥');
  currencySymbols.set('KRW', '₩');
  currencySymbols.set('NGN', '₦');
  currencySymbols.set('PHP', '₱');
  currencySymbols.set('PLN', 'zł');
  currencySymbols.set('PYG', '₲');
  currencySymbols.set('THB', '฿');
  currencySymbols.set('UAH', '₴');
  currencySymbols.set('VND', '₫');

  if (currencySymbols.has(currency)) {
    return currencySymbols.get(currency) || currency;
  }

  return currency;
}

export const daysAgo = (dateTime: DateTimeType) => {
  if (!dateTime) {
    return "0 ago";
  }

  return dayjs(dateTime).fromNow();
}

export function dateFromNow(dateTime: DateTimeType) {
  const daysAgoValue = daysAgo(dateTime);
  const _date = dayjs.utc(dateTime);

  return daysAgoValue?.includes("month") ?
    _date.format("D MMMM") : daysAgoValue?.includes("year") ?
      _date.format("D MMMM, YYYY") : daysAgoValue;
}

export const getDayAndMonth = (date?: DateTimeType) => date ? dayjs.utc(date).format("MMM DD") : "";

export const getTimeIn24Hours = (date?: DateTimeType) => date ? dayjs.utc(date).format("H:mm") : "";

export const generateColor = (index: number): string => {
  const avatarColors = ['blue-10', 'green-10', 'yellow-10', 'red-10', 'primary-10'];

  return avatarColors[index % avatarColors.length];
}

export const getBaseUrl = (): string => {
  const { hash, href } = window.location;
  return href.replace(hash, "");
}

export function isStringArray(value: any): value is string[] {
  return isArray(value) && value.every((i: any) => typeof i === "string");
}

export function getSentryEnvironment(): string {
  const { pathname, host } = window.location;

  if (pathname.includes("re-cloud-staging")) {
    return "staging";
  } else if (host.includes("cloud.getre.io")) {
    return "production";
  } else if (host.includes("localhost")) {
    return "local";
  }

  return "feature-branch";
}

export function isArray(obj: any): boolean {
  return Array.isArray(obj)
}

export function isArrayEmpty(obj: any): boolean {
  return obj?.length === 0;
}

export function loadingDelay(promise: any, time = 200) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  }).then(() => promise);
}

export function lazyRetry<T>(componentImport: () => Promise<T>, name: string): Promise<T> {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behavior as already tried refresh
    });
  });
};

export const hasDecimals = (n: number): boolean => (n - Math.floor(n)) !== 0;

export const formatValue = (n: number) => hasDecimals(n) ? Number(n.toFixed(2)) : n;
