import logo from "images/redata.svg";
import "./loading-logo.css";

function LoadingLogo() {
  return (
    <div
      className="loading-logo-container flex flex-col items-center justify-center w-full h-screen"
      data-testid="test-LoadingLogo"
    >
      <img src={logo} className="h-24 w-auto mb-6" alt="logo" loading="lazy" />
      <div className="loading">
        <div className="arc"></div>
        <div className="arc"></div>
        <div className="arc"></div>
      </div>
    </div>
  );
}

export default LoadingLogo;
