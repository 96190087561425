import { capitalize } from "utils";
import "./status-cell.css";

interface StatusCellProps {
  value: any;
}

function StatusCell(props: StatusCellProps): JSX.Element {
  const { value } = props;
  return (
    <div className="status-cell-container" data-testid="test-StatusCell">
      <span
        className={`${value?.toLowerCase()} text-xs font-semibold text-center py-1 rounded-full`}
      >
        {capitalize(value)}
      </span>
    </div>
  );
}

export default StatusCell;
