import { GoogleLogin } from "@react-oauth/google";
import "./sso.css";

function Sso({ onSubmit }: any) {
  const handleGoogleLogin = (credentialResponse: any) => {
    const data = {
      provider: "google",
      credentials: credentialResponse.credential,
    };

    return onSubmit(data);
  };

  return (
    <>
      <div className="login-or-container">
        <span className="login-or-content">or</span>
      </div>

      <div className="mt-4 w-full flex items-center justify-center">
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={console.log}
          useOneTap
        />
      </div>
    </>
  );
}
export { Sso };
