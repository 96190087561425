import { memo } from "react";

export type PaginationProps = {
  has_next?: boolean;
  has_prev?: boolean;
  next_num?: number | null;
  page?: number | null;
  pages?: number | null;
  prev_num?: null | null;
  total?: number;
  handlePageNumberClick: ((pageNumber: number) => void) | undefined;
};

function Pagination(props: PaginationProps) {
  const {
    has_next,
    has_prev,
    next_num,
    page,
    prev_num,
    handlePageNumberClick,
  } = props;

  return (
    <div className="flex justify-center py-4">
      <nav aria-label="Page navigation example">
        <ul className="flex list-style-none">
          <li className={`page-item ${!has_prev ? "disabled" : ""}`}>
            <button
              disabled={!has_prev ? true : false}
              onClick={handlePageNumberClick?.bind(null, +(page || 1) - 1)}
              className={has_prev ? "enabled-btn" : "disabled-btn"}
              aria-disabled={!has_prev ? "true" : "false"}
            >
              Previous
            </button>
          </li>

          {prev_num && (
            <li className="page-item mr-1">
              <button
                onClick={handlePageNumberClick?.bind(null, prev_num)}
                className="page-link w-[2.25rem] h-[2.25rem] relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
              >
                {prev_num}
              </button>
            </li>
          )}

          <li className="page-item mr-1 active">
            <button className="page-link w-[2.25rem] h-[2.25rem] relative block py-1.5 px-3 border-0 bg-primary outline-none transition-all duration-300 rounded-full text-white hover:text-white hover:bg-primary2 focus:shadow-none">
              {page}
              <span className="visually-hidden">(current)</span>
            </button>
          </li>

          {next_num && (
            <li className="page-item mr-1">
              <button
                onClick={handlePageNumberClick?.bind(null, next_num)}
                className="page-link w-[2.25rem] h-[2.25rem] relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
              >
                {next_num}
              </button>
            </li>
          )}

          <li className={`page-item ${!has_next ? "disabled" : ""}`}>
            <button
              disabled={!has_next ? true : false}
              onClick={handlePageNumberClick?.bind(null, +(page || 0) + 1)}
              className={has_next ? "enabled-btn" : "disabled-btn"}
              aria-disabled={!has_next ? "true" : "false"}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default memo(Pagination);
